import React from 'react';
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PageLayout from 'components/layouts/page/page.layout';
import { logout } from 'state/account/account.reducers';
import { ResourcesFileListProps } from 'components/resources-file-list/resources-file-list.props';
import ResourcesFileList from 'components/resources-file-list/resources-file-list.component';

const SignOut = ({ data }: { data: GatsbyTypes.SignOutDataQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    if (typeof window !== 'undefined') {
        dispatch(logout({}));
        const resourcesFileListProps: ResourcesFileListProps = {
            sectionIndex: 1,
            title: t('pages.sign-out.title')
        };

        return (
            <PageLayout metaData={{ nodeTitle: t('pages.sign-out.title') }}>
                <ResourcesFileList {...resourcesFileListProps} />
            </PageLayout>
        );
    } else {
        return null;
    }
};

export default SignOut;

export const query = graphql`
    query SignOutData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
